import React from 'react';
import TopicSubhead from "../components/TopicSubhead"
import Heading from "../components/Heading"

function EmphasisBlockSet({ blocks }) {
	const featuredBlocks = blocks.filter(block => {
		return block.blockIsFeatured;
	});

	const standardBlocks = blocks.filter(block => {
		return !block.blockIsFeatured;
	});

	return (
		<div className="emphasis-block-set">
			<div className="emphasis-block-set__row">
				{ featuredBlocks.map((block, i) => {
						return (
							<div className="emphasis-block emphasis-block--featured" key={`featured-${i}`}>
								<TopicSubhead>{block.blockTopic}</TopicSubhead>

								<Heading hasLine={false}>
									{ block.blockHeading }
								</Heading>

								<div
									className="emphasis-block__text"
									dangerouslySetInnerHTML={{__html: block.blockText }}/>
							</div>
						);
					})
				}
			</div>
			<div className="emphasis-block-set__row">
				{
					standardBlocks.map((block, i) => {
						const defaultFlexBasis = Math.floor(100 / standardBlocks.length);
						const style = {
							flexBasis: `${defaultFlexBasis}%`
						};

						return (
							<div className="emphasis-block" key={i} style={style}>
								<TopicSubhead>{block.blockTopic}</TopicSubhead>

								<Heading hasLine={false}>
									{ block.blockHeading }
								</Heading>

								<div
									className="emphasis-block__text"
									dangerouslySetInnerHTML={{__html: block.blockText }}/>
							</div>
						);
					})
				}
			</div>
		</div>
	)
}

export default EmphasisBlockSet;
