import React from 'react';
import DecorativeLink from "../components/DecorativeLink"

function IntroBlock({ id, heading, introLabel, introURL, text, dividerColor }){
  return (
    <div
      id={id || null}
      className={`
        intro-block
        intro-block--${id}
      `}
    >
      <div className="intro-block__heading">
        <h2>{heading}</h2>

        <DecorativeLink
          href={introURL}
          lineColor={dividerColor}
          >
          {introLabel}
        </DecorativeLink>
      </div>
      <div
        className="intro-block__text"
        style={{
          borderColor: dividerColor !== undefined ? dividerColor : 'transparent',
        }}
        dangerouslySetInnerHTML={{__html: text}}
      >
        
      </div>
    </div>
  )
}

export default IntroBlock;
