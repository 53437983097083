import React from 'react';
import TopicSubhead from "../components/TopicSubhead"
import Heading from "../components/Heading"

function Callout({ id, heading, topic, children, dividerColor, textColor, backgroundColor, hasLine }){
  return (
    <div id={id || null} className='callout' style={{
      backgroundColor: backgroundColor ? backgroundColor : '#172d6a',
      color: textColor ? textColor : '#fff'
    }}>
      <TopicSubhead>{topic}</TopicSubhead>

      <Heading hasLine={hasLine === false ? null : true} lineColor={dividerColor}>
        { heading }
      </Heading>

      <div className="callout__text" 
      dangerouslySetInnerHTML={{__html: children}}>
      </div>
    </div>
  )
}

export default Callout;
