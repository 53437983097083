import React from 'react';
import findByType from '../utils/findByType';

const Column = () => null;

const Columns = ({ children, isMobile }) => {
	const columns = findByType(children, Column);
	if (!columns) return null;

	const defaultFlexBasis = Math.floor(100 / columns.length);
	const _style = {
		flexBasis: `${defaultFlexBasis}%`
	};

	return columns.map((col,i) => {
		const classNameString = col.props.isFeatured ? 'column-set__column--featured' : '';

		return (
			<div className={`column-set__column ${classNameString}`} key={i} style={_style}>
				<div className="column-content">
					{col.props.children}
				</div>
			</div>
		)
	});
}

function ColumnSet(props) {
	const columnCountClass = props.children.length < 2 ? 'column-set--layout-single' : 'column-set--layout-multiple';

	return (
		<div className={`column-set ${columnCountClass}`}>
			<Columns children={props.children}></Columns>
		</div>
	)
}

ColumnSet.Column = Column;
export default ColumnSet;
