import React from 'react';
import DecorativeLink from "../components/DecorativeLink"

function IntroBlock2({ id, introHeading,introLabel, introURL, introServices, primaryColor }) {

  const headingColor = (primaryColor === "#bee9f6" || primaryColor === "#deec1e") ? "#162d6a" : primaryColor;

  return (
    <div
      id={id || null}
      className={`
        intro-block
        intro-block-2
        intro-block--${id}
      `}
    >
      <div className="intro-block__heading" >
        {introHeading.map((heading) => <h3 className="intro-block-2__heading" style={{ color: headingColor }}>{heading}</h3>)}
        <div className="intro-block-2__heading">
          <DecorativeLink
            href={introURL}
            lineColor={primaryColor}
            >
            {introLabel}
          </DecorativeLink>          
        </div>
      </div>
        <div className="introBlockDivider" style={{ borderColor: primaryColor }}></div>
      <div class="emphasis-block-set"><div class="emphasis-block-set__row"><div class="emphasis-block emphasis-block--featured"><div class="topic-subhead"><p>Services</p></div><div class="emphasis-block__text">{introServices}</div></div></div><div class="emphasis-block-set__row"></div></div>


    </div>
  )
}

export default IntroBlock2;
