import React from 'react'
import Layout from '../components/Layout'
import Section from '../components/Section'
import ColumnSet from '../components/ColumnSet'
import EmphasisBlockSet from '../components/EmphasisBlockSet'
import Seo from '../components/Seo'
import Image from '../components/Image'
import Icon from '../components/Icon'
import { graphql } from 'gatsby'
import SectionBanner from '../components/SectionBanner'
import IntroBlock from '../components/IntroBlock'
import IntroBlock2 from '../components/IntroBlock2'
import Callout from '../components/Callout'
import TopicSubhead from '../components/TopicSubhead'
import Heading from '../components/Heading'
import parse, { domToReact } from 'html-react-parser'
import DecorativeLink from '../components/DecorativeLink'

export default function CaseStudyTemplate({ data }) {
  const caseStudy = data.caseStudiesJson

  return (
    <Layout id={caseStudy.jsonId}>
      <Seo
        title={caseStudy.title}
        description={caseStudy.description}
        relPath={`/case-studies/${caseStudy.jsonId}/`}
      />

      <div id="case-study" className="page--case-study">
        <SectionBanner
          id="hero"
          bgColor={
            caseStudy.primaryColor !== null ? caseStudy.primaryColor : null
            // TODO: make sure the fall back renders black text on initial bg color
          }
          bgImage={`case-studies/${caseStudy.heroImage}`}
          bgTextShade={true}
          parentTitle={'Our Work'}
          title={caseStudy.title}
          pipeColor={'#fff'}
        />

        <Section id={'intro-section'} indent constrain spacing={30}>
          {caseStudy.introTemplate === "new" ? <IntroBlock2
            id="case-study-intro"
            introHeading={caseStudy.introHeading}
            introLabel={caseStudy.caseStudyLabel}
            introURL={caseStudy.caseStudyURL}
            introServices={caseStudy.introServices}
            primaryColor={caseStudy.primaryColor}
          /> : <IntroBlock
            id="case-study-intro"
            heading={caseStudy.details.client}
            dividerColor={caseStudy.primaryColor}
            introLabel={caseStudy.caseStudyLabel}
            introURL={caseStudy.caseStudyURL}
            text={caseStudy.description}
          />}
        </Section>

        {caseStudy.sections.map((content, i) => {
          if (content.section.type === 'columns') {
            return (
              <Section
                id={`${caseStudy.id}-section--columns-${i}`}
                indent
                key={i}
                classNamesArray={['section--columns']}
                constrain
                spacing="20"
              >
                <ColumnSet>
                  {content.section.columns.map((column, i) => {
                    const columnText = `${column.columnText}`

                    const options = {
                      replace: domNode => {
                        if (!domNode.attribs) return

                        if (domNode.name === 'a') {
                          return (
                            <>
                              <DecorativeLink href={domNode.attribs.href}>
                                {domToReact(domNode.children, options)}
                              </DecorativeLink>
                            </>
                          )
                        }
                      },
                    }

                    return (
                      <ColumnSet.Column key={i}>
                        {column.columnImage !== null ? (
                          <Image
                            src={`case-studies/${column.columnImage}`}
                            alt={column.columnImageAlt}
                          />
                        ) : null}

                        {column.columnIconType ? (
                          <div className="column-content__icon">
                            <Icon type={column.columnIconType}></Icon>
                          </div>
                        ) : null}

                        <TopicSubhead>{column.columnTopic}</TopicSubhead>

                        <Heading
                          hasLine={true}
                          lineColor={caseStudy.primaryColor}
                        >
                          {column.columnHeading}
                        </Heading>

                        {column.columnText && (
                          <div className="column-content__text">
                            {parse(columnText, options)}
                          </div>
                        )}
                      </ColumnSet.Column>
                    )
                  })}
                </ColumnSet>
              </Section>
            )
          } else if (content.section.type === 'banner') {
            return (
              <SectionBanner
                id="banner"
                bgImage={`case-studies/${content.section.sectionImage}`}
                key={`banner-${i}`}
              />
            )
          } else if (content.section.type === 'emphasis-blocks') {
            return (
              <Section
                id={`${caseStudy.jsonId}-section--emphasis-blocks-${i}`}
                indent
                key={i}
                classNamesArray={['section--emphasis-blocks']}
                constrain
                spacing="20"
              >
                <EmphasisBlockSet blocks={content.section.blocks} />
              </Section>
            )
          } else if (content.section.type === 'callout') {
            return (
              <Section
                id={`${caseStudy.jsonId}-section--callout-${i}`}
                indent
                key={i}
                classNamesArray={['section--callout']}
                constrain
                spacing="20"
              >
                <Callout
                  topic={content.section.callout.calloutTopic}
                  heading={content.section.callout.calloutHeading}
                  dividerColor={caseStudy.primaryColor}
                  backgroundColor={caseStudy.secondaryColor}
                >
                  {content.section.callout.calloutText}
                </Callout>
              </Section>
            )
          } else if (content.section.type === 'image-quad') {
            return (
              <Section
                id={`${caseStudy.jsonId}-section--image-quad-${i}`}
                indent
                key={i}
                classNamesArray={['section--image-quad']}
                constrain
                spacing="20"
              >
                <div className="image-quad">
                  {content.section.quadImages.map((image, i) => {
                    return (
                      <div className="image-quad__container" key={i}>
                        {image.quadImage !== null ? (
                          <Image
                            src={`case-studies/${image.quadImage}`}
                            alt={image.quadImageAlt}
                          />
                        ) : null}
                      </div>
                    )
                  })}
                </div>
              </Section>
            )
          } else {
            return null
          }
        })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CaseStudyTemplateNew($jsonId: String!) {
    caseStudiesJson(jsonId: { eq: $jsonId }) {
      jsonId
      description
      introTemplate
      introHeading
      introServices
      caseStudyURL
      caseStudyLabel
      details {
        client
        project
        technology
      }
      heroImage
      heroImageAlt
      logo
      logoAlt
      primaryColor
      title
      sections {
        section {
          type
          bgColor
          sectionImage
          sectionImageAlt
          callout {
            calloutHeading
            calloutText
          }
          quadImages {
            quadImage
            quadImageAlt
          }
          blocks {
            blockTopic
            blockHeading
            blockIsFeatured
            blockText
          }
          columns {
            columnHeading
            columnTopic
            columnImage
            columnImageAlt
            columnIconType
            columnText
          }
        }
      }
    }
    images: allFile(filter: { relativeDirectory: { eq: "case-studies" } }) {
      edges {
        node {
          id
          publicURL
          base
        }
      }
    }
  }
`
